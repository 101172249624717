import React, { useEffect } from "react";
import CornerLadybug from "../images/corner/corner-ladybug-art.webp";
import Vineyard from "../images/vineyard.png";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { MARKS } from "@contentful/rich-text-types";

const Winemakers = ({ info: { fields } }) => {
  useEffect(() => {
    document.title = "Auburn Road - The Winemakers";
    return () => {
      document.title = "Auburn Road Vineyards";
    };
  }, []);

  const options = {
    renderMark: {
      [MARKS.BOLD]: (text) => <span className="fw-bold">{text}</span>,
    },
  };
  return (
    <>
      <img
        className="corner-ladybug"
        src={CornerLadybug}
        alt="ladybug label - auburn road"
      />
      <div className="container p-t-150">
        <div className="top-quote text-center m-auto">
          <p>{fields.pageQuote}</p>
          <h2 className="huge-text-no-quote m-b-10">Julianne Donnini</h2>
        </div>
        <div className="flex-1 m-r-0 m-md-r-50 m-t-100">
          <div className="d-flex flex-column flex-md-row align-items-center align-items-md-start m-t-40">
            <div className="flex-1 m-r-0 m-md-r-30">
              {documentToReactComponents(fields.meetTheWinemaker, options)}
            </div>
            <div className="flex-1">
              <img
                className="w-75 m-auto"
                src={fields.winemakerImage.fields.file.url}
                alt={fields.winemakerImage.fields.title}
              />
            </div>
          </div>

          <div className="d-flex flex-column-reverse flex-md-row align-items-center m-b-150 m-t-100">
            <div className="flex-1">
              <img
                className="w-100"
                src={fields.julesTeam.fields.file.url}
                alt="winemakers at auburn road vineyard and winery"
              />
            </div>
            <div className="flex-1 m-t-50 m-md-t-50 m-l-0 m-md-l-30 b-r-5">
              {documentToReactComponents(fields.meetJulesTeam, options)}
            </div>
          </div>
        </div>
      </div>
      <img src={Vineyard} className="corner-vineyard" alt="decorative" />
      <div className="container">
        <div>
          <div className="m-t-75 m-b-100 winemakers-content position-relative">
            {documentToReactComponents(fields.meetTheVineyard, options)}
          </div>
        </div>
        <div className="m-b-100 inner-container">
          <h2 className="m-b-10">Take a drone flight through the vines!</h2>
          <p className="m-b-20">
            Strap in as slightly insane daredevil drone pilot Jake Donnini takes
            you on a thrill ride through the vines!
          </p>
          <iframe
            width="100%"
            height="315"
            src="https://www.youtube.com/embed/KcfSHl9KEzE?enablejsapi=1"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
        <div className="m-b-100 inner-container">
          <h2 className="m-b-10">REOPENINGS: A DOCUMENTARY BY BEN HORNER</h2>
          <p className="m-b-20">
            In June of 2020, filmmaker Ben Horner followed a small, agricultural
            business - Auburn Road Vineyards - as it prepared to reopen
            following the COVID-19 Pandemic lockdown which lasted over 3
            months.  The following documentary is the result.
          </p>
          <iframe
            width="100%"
            height="315"
            src="https://www.youtube.com/embed/huHlp7rLA5E?enablejsapi=1"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </>
  );
};

export default Winemakers;
