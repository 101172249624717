import React from "react";
import { useHistory } from "react-router";
import Button from "../components/Button";

const WineBlock = (props) => {
  const history = useHistory();

  const { description, wineArt, wineName, cellarWines } = props.wineInfo;
  const shortenedDesc = () => {
    if (!description) {
      return null;
    }
    const length = 120;
    const desc = description;
    const trimmedDesc = desc.substring(0, Math.min(length, desc.length));
    if (description.length < length) {
      return description;
    } else {
      return trimmedDesc + "...";
    }
  };
  const hyphenWineName = wineName.split(" ").join("-").toLowerCase();
  if (!props.wineInfo.wineName || !props.wineInfo.wineArt) {
    return null;
  }

  const renderCellarVintages = () => {
    if (cellarWines && cellarWines[0].fields) {
      let vintages = [];
      cellarWines.map((wine) => vintages.push(wine.fields.vintage));
      return (
        <div className="m-b-10">
          <p className="i b mb-0">From the Cellar vintages:</p>
          {vintages.join(", ")}
        </div>
      );
    } else return null;
  };

  return (
    <div className="wine-block m-b-10 m-md-b-50">
      <div className="wine-block__content p-md-b-0 align-items-center">
        <img
          className="wine-art pointer"
          onClick={() => history.push("/" + hyphenWineName)}
          src={wineArt.fields.file.url}
          alt={wineName + " art"}
        />
        <div className="flex-2">
          <h3 className="m-b-5">{wineName}</h3>
          <p className="wine-description">{shortenedDesc()}</p>
          {renderCellarVintages()}
          <div className="wine-block__button-wrap">
            <Button
              href="https://auburn-road-vineyard-winery-enoteca.square.site/"
              type="secondary"
              small
            >
              buy now
            </Button>
            <Button
              onClick={() => history.push("/" + hyphenWineName)}
              type="plain"
              small
            >
              learn more
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default WineBlock;
