import React, { useEffect } from "react";
import GPAC from "../images/corner/corner-gpac.webp";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { MARKS } from "@contentful/rich-text-types";

const Sustainability = ({ sustainabilityInfo }) => {
  useEffect(() => {
    document.title = "Auburn Road - Sustainability";
    return () => {
      document.title = "Auburn Road Vineyards";
    };
  }, []);

  const options = {
    renderMark: {
      [MARKS.BOLD]: (text) => <span className="fw-bold">{text}</span>,
    },
  };

  return (
    <div className="position-relative p-b-200">
      <img className="corner-gpac" src={GPAC} alt="" />
      <div className="inner-container m-auto p-t-150">
        <h2 className="huge-text-no-quote m-b-100">Sustainability</h2>
        <h3 className="m-b-10">Our commitment to Sustainability</h3>
        {documentToReactComponents(
          sustainabilityInfo[0].fields.mainText,
          options
        )}
      </div>
    </div>
  );
};

export default Sustainability;
