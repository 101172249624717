import React, { useEffect } from "react";
import GPAC from "../images/corner/corner-gpac.webp";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { MARKS } from "@contentful/rich-text-types";
import GPACArt from "../images/gpac-art.png";
import EidolonArt from "../images/Eidolon-art.png";

const Sustainability = ({ givingBackInfo }) => {
  useEffect(() => {
    document.title = "Auburn Road - Sustainability";
    return () => {
      document.title = "Auburn Road Vineyards";
    };
  }, []);

  const options = {
    renderMark: {
      [MARKS.BOLD]: (text) => <span className="fw-bold">{text}</span>,
    },
  };

  return (
    <div className="position-relative p-b-200">
      <div className="inner-container m-auto p-t-150">
        <h2 className="huge-text-no-quote m-b-100">
          Giving Back to the community
        </h2>
        <h3 className="m-b-10">Charitable Partnerships</h3>
        {documentToReactComponents(givingBackInfo[0].fields.partnerships)}
        <div className="d-flex align-items-start flex-column flex-md-row m-t-50">
          <div className="m-r-0 m-md-r-30">
            <img className="width-150" src={GPACArt} alt="" />
          </div>
          <div>
            <h3 className="m-b-10 m-t-30 m-md-t-0">
              The Give Peach a Chance Charity of the Year
            </h3>
            {documentToReactComponents(
              givingBackInfo[0].fields.givePeachAChance
            )}
          </div>
        </div>
        <div className="d-flex align-items-start flex-column flex-md-row m-t-50">
          <div className="m-r-0 m-md-r-30">
            <img className="width-150" src={EidolonArt} alt="" />
          </div>
          <div>
            <h3 className="m-b-10 m-t-30 m-md-t-0">
              Eidólon and the Walt Whitman Association
            </h3>
            {documentToReactComponents(
              givingBackInfo[0].fields.eidolonWaltWhitman
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sustainability;
